.image-preview-fullframe {
  width: 90%;
  max-width: 500px;
  min-height: 200px;
  margin-left: 5%;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  image-orientation: 0deg;
  border-radius: 4px;
  cursor: pointer;
}

.image-preview-fullframe-icon-container {
  width: 100%;
  height: 100%;
  min-height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--backgroundColor-secondary);
  border-radius: 4px;
}

.image-preview-fullframe-icon-container:hover {
  box-shadow: 0 0 5px rgba(0,0,0,0.7);
}

.image-preview-fullframe-icon {
  width: 20%;
  height: 20%;
  fill: var(--text-dark);
}

.image-preview-fullframe-close-container {
  width: 25px;
  height: 25px;
  padding: 3px;
  position: absolute;
  top: -6px;
  right: -12px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  fill: var(--stay-white);
  background-color: var( --secondary);
  box-shadow: -1px 1px 3px rgba(0,0,0,0.6);
  z-index: 1;
  cursor: pointer;
}
