.profile-description-container {
  width: calc(100% - 10px);
  margin: 3px 5px;
  min-height: 40px;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  font-size: 1.3rem;
  background-color: var(--background-on-opacity);
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.business-title-container {
  margin-top: -3px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.business-line-break {
  width: 100%;
  height: 1px;
}

.business-title-text {
  margin: 0px 10px;
  font-size: 1.4rem;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
}

.profile-pagination-title {
  padding-bottom: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.1rem;
  font-weight: 500;
}

.profile-description-container > p {
  margin: 10px;
}

.profile-day-text {
  min-width: 40px;
}

.profile-time-text {
  min-width: 90px;
  text-align: right;
}

.profile-day-closed-text {
  padding-right: 5px;
  text-align: center;
}

.profile-ads-title-container {
  width: calc(100% - 47px);
  height: 40px;
  padding-top: 5px;
  margin-left: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  font-size: 1.1rem;
  color: #999b9c;
  color: var(--text-dark);
}
.profile-ads-title-container > p {
  font-size: 1.3rem;
}

.profile-account-type {
  color: var(--primary);
}

.profile-ad-info-container {
  width: calc(100% - 95px);
  margin-left: 5px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
}

.profile-ad-category-container {
  width: 23px;
  height: 23px;
  margin-right: 10px;
  fill: var(--stay-white);
}

.ad-inactive-text {
  fill: #999b9c;
  fill: var(--text-medium);
  color: #999b9c;
  color: var(--text-medium);
}

.profile-ad-manage-container {
  width: 80px;
  margin-right: 5px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: nowrap;
}

.profile-ad-delete-container {
  width: 26px;
  height: 26px;
  padding: 4px;
  margin-left: 10px;
  fill: hsl(6, 100%, 75%);
  fill: var(--secondary-light);
  border: 1px solid var(--secondary-light);
  border-radius: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
  cursor: pointer;
}

.profile-accont-header {
  width: 95%;
  margin-left: 2.5%;
  margin-bottom: 12px;
  justify-content: space-between;
  align-items: center;
}

.profile-post-ad-container {
  width: 95%;
  margin-left: 2.5%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.profile-account-text {
  font-size: 1.3rem;
}

.profile-account-promo-text {
  font-size: 0.9rem;
}

.profile-accont-promo {
  width: 95%;
  height: 70px;
  margin-left: 2.5%;
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
}

.profile-flex-end {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.business-selector-container {
  width: 100%;
  height: 45px;
  margin-bottom: 20px;
  border-radius: 6px;
  background-color: var(--text-medium);
  box-shadow: 0 4px 8px rgba(10, 10, 10, 0.7);
  overflow: hidden;
  z-index: 99;
}

.profile-ad-unique-text {
  font-size: 1rem !important;
  white-space: nowrap;
}

.profile-ad-unique-text-date {
  color: rgb(0, 255, 85) !important;
}

.profile-category-upgarde-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.profile-category-upgarde-text {
  margin-bottom: 15px;
  font-size: 1rem;
  font-weight: 500;
  color: var(--text-dark);
}

.no-locations-animation-container {
  width: 50%;
}
