.user-onboarding-background-image {
  width: 100vw;
  min-width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  object-fit: cover;
  object-position: 50%;
}

.user-onboarding-wrapper {
  width: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow-y: scroll;
  z-index: 1;
}

.user-onboarding-container {
  width: calc(100% - 20px);
  max-width: 600px;
  padding: 10px 10px 25px 10px;
  margin: 40px 0px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: var(--backgroundColor);
  border-radius: 5px;
  box-shadow: 0px 7px 50px rgba(0, 0, 0, 1);
}

.user-onboarding-LogoName {
  width: 70%;
  max-width: 300px;
  height: 25px;
  fill: var(--text-dark);
}

.user-onboarding-title {
  margin: 5px 0px;
  font-size: 1.4rem;
  font-weight: 700;
  color: var(--text-dark);
  z-index: 2;
}

.user-onboarding-sub-title {
  width: 100%;
  margin-top: 5px;
  font-size: 1rem;
  font-weight: 600;
  text-align: center;
  color: var(--text-dark);
}

.user-onboarding-conatiner {
  width: 55%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.listing-flow-title {
  font-size: 1.4rem;
  font-weight: 700;
  color: var(--text-dark);
}

.listing-flow-fine-text {
  width: 100%;
  margin-top: 8px;
  flex-shrink: 0;
  font-size: 1.1rem;
  font-weight: 600;
  text-align: center;
  color: var(--primary);
}

.listing-flow-image-shadow {
  width: calc(100% - 20px);
  height: calc(100% - 15px);
  position: absolute;
  top: 10px;
  left: 8px;
  border-radius: 42px;
  transition: all 0.5s ease;
}

.user-onboarding-image {
  width: 100%;
  height: 100%;
  position: absolute;
  display: none;
  transition: all 0.5s ease;
  cursor: pointer;
  z-index: 3;
}

.listing-flow-image-placeholder {
  height: 100%;
  margin-top: 5px;
  position: relative;
  top: 0px;
  flex-shrink: 0;
  display: block;
  visibility: hidden;
}

.listing-flow-image-show {
  display: block;
}

.user-onboarding-conatiner:hover .listing-flow-image-shadow {
  transform: scale(1.3);
  transform-origin: bottom center;
  background-color: rgba(0, 0, 0, 0.8);
  box-shadow: 0px -10px 50px rgba(0, 0, 0, 1);
}
.user-onboarding-conatiner:hover .listing-flow-image-0 {
  transform: scale(1.3);
  transform-origin: bottom center;
}
.user-onboarding-conatiner:hover .listing-flow-image-1 {
  transform: scale(1.3);
  transform-origin: bottom center;
}
.user-onboarding-conatiner:hover .listing-flow-image-2 {
  transform: scale(1.3);
  transform-origin: bottom center;
}
.user-onboarding-conatiner:hover .listing-flow-image-3 {
  transform: scale(1.3);
  transform-origin: bottom center;
}
.user-onboarding-conatiner:hover .listing-flow-image-4 {
  transform: scale(1.3);
  transform-origin: bottom center;
}
.user-onboarding-conatiner:hover .listing-flow-image-5 {
  transform: scale(1.3);
  transform-origin: bottom center;
}
