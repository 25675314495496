.legend-form-split-container {
  width: calc(100% - 20px);
  margin: 0 !important;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
}

.legend-form-text {
  width: 100%;
  margin-right: 10px;
  font-size: 0.9rem;
  text-align: right;
  white-space: nowrap;
}

.legend-form-complete {
  color: var(--primary);
}

.legend-form-incomplete {
  font-weight: 500;
  color: var(--secondary-light);
}

.listingCommon-location-conatiner {
  width: calc(100% - 20px);
  padding: 5px 0 5px 5px !important;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.listingCommon-close-svg-conatiner {
  min-width: 25px;
  width: 25px;
  height: 25px;
  padding: 3px;
  margin-right: 5px;
  border-radius: 3px;
  background-color: var(--secondary);
  fill: var(--stay-white);
}

.listingCommon-location-input {
  width: 100%;
  margin-right: 5px;
  background-color: transparent;
  border: none;
}

.listingCommon-location-button {
  width: 120px;
  height: 35px;
  padding: 0 15px 0 10px;
  margin-right: -5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0 3px 3px 0;
  color: var(--stay-white);
  background-image: linear-gradient( var(--primary), var(--primary-dark), var(--primary));
}

.listingCommon-location-svg-conatiner {
  width: 12px;
  margin-right: 10px;
  fill: var(--stay-white);
}

.checkbox-label-disabled {
  color: var(--text-light);
}

.use-business-hours-corporate-text {
  width: 100%;
  padding: 10px 0px 5px 0px;
  font-size: 1.1rem;
  font-weight: 600;
  color: var(--primary);
  text-align: center;
}

.textGrowIn {
  animation-name: textGrowIn;
  animation-fill-mode: forwards;
  animation-duration: 500ms;
  transform: scale(0);
}

@keyframes textGrowIn {
  0% {
      transform: scale(0);
  }
  100% {
      transform: scale(1.0);
  }
}