:root {
  --primary-dark: hsl(215, 100%, 50%);
  --primary: hsl(215, 100%, 65%);
  --primary-light: hsl(215, 100%, 80%);
  --primary-opacity: rgba(77, 151, 255, 0.3);
  --primary-opacity-dark: rgba(77, 151, 255, 0.6);
  --secondary-dark: hsl(339, 100%, 40%);
  --secondary: hsl(339, 100%, 55%);
  --secondary-light: hsl(339, 100%, 70%);
  --secondary-opacity: rgba(255, 26, 106, 0.3);
  --secondary-opacity-dark: rgba(255, 26, 106, 0.6);
  --text-extra-dark: rgb(40, 40, 40);
  --text-dark: rgb(80, 80, 80);
  --text-medium: #999b9c;
  --text-light: #c3c3c3;
  --text-white: #f1f1f1;
  --stay-white: #f1f1f1;
  --stay-dark: rgb(80, 80, 80);
  --error: hsl(6, 100%, 60%);
  --backgroundColor: rgb(247, 247, 247);
  --backgroundColor-secondary: rgb(210, 210, 210);
  --background-opacity: rgba(247, 247, 247, 0.95);
  --background-on-opacity: rgb(255, 255, 255);
  --opacity-white-dark: rgba(255, 255, 255, 0.9);
  --opacity-white-medium: rgba(255, 255, 255, 0.7);
  --opacity-white-light: rgba(255, 255, 255, 0.5);
  --opacity-dark: rgba(255, 255, 255, 0.9);
  --opacity-medium: rgba(255, 255, 255, 0.8);
  --opacity-light: rgba(255, 255, 255, 0.7);
  --opacity-very-light: rgba(255, 255, 255, 0.05);
  --skeleton: rgb(210, 210, 210);
  --skeleton-opacity: rgba(210, 210, 210);
  color: rgb(40, 40, 40);
  font-size: 14px;
}

input,
input[type="color"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"],
select,
textarea {
  font-size: 15px !important;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  height: 15px;
  width: 15px;
  color: var(--primary);
  cursor: pointer;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  background-color: rgb(236, 236, 236);
  background-color: var(--backgroundColor-secondary);
  overflow: hidden;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}

a {
  text-decoration: none;
}

.no-scroll-bars::-webkit-scrollbar {
  display: none;
  width: 0 !important;
  background: transparent;
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
}

.input-style {
  border-style: inset;
  border-radius: 4px;
  border-width: 2px;
  border-color: var(--text-light);
  background-image: linear-gradient(rgb(230, 230, 230), rgb(255, 255, 255));
}

.rotate180 {
  transform: rotate(180deg);
}

.color-default {
  color: var(--text-dark);
}

.color-white {
  color: var(--stay-white);
}

.color-primary {
  color: var(--primary);
}

.color-secondary {
  color: var(--secondary);
}

.fill-primary > svg {
  fill: hsl(6, 100%, 60%);
  fill: var(--primary);
}

.fill-secondary > svg {
  fill: hsl(197, 99%, 40%);
  fill: var(--secondary);
}

.bgc-white {
  color: rgb(247, 247, 247);
}

.bgc-primary {
  background-color: var(--primary);
}

.bgc-primary-dark {
  background-color: var(--primary-dark);
}

.bgc-secondary {
  background-color: var(--secondary);
}

.card-wrapper:last-child {
  padding-bottom: 30px;
}

.card {
  margin-top: 12px;
  background-color: rgb(247, 246, 246);
  background-color: var(--backgroundColor);
  box-shadow: 0 0 2px rgba(10, 10, 10, 0.8);
}

.card-form {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
}

.card-info-form {
  width: 100%;
  max-width: 550px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.card-info-form > * {
  margin-top: 10px;
}

.label-form {
  width: 90%;
  margin-left: 2.5%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.1rem;
}

.image-category-input-container {
  width: 90%;
  margin-left: 2.5%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}

.image-label-form {
  margin-right: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 1.1rem;
}

.image-label-form-category {
  font-weight: 700;
  color: var(--secondary);
  white-space: nowrap;
}

.show-form-toggle {
  display: flex;
  justify-content: center;
  align-items: center;
}

.show-form-toggle-text {
  margin-right: 5px;
}

.legend-form {
  margin: 10px 0 10px 5%;
  font-size: 0.8rem;
  color: #6d6d6d;
  color: var(--text-dark);
}

.input-form {
  width: 90%;
  max-width: 500px;
  margin-left: 5%;
  padding: 5px;
  border-style: inset;
  border-width: 2px;
  border-color: var(--text-light);
}

.select-form {
  height: 35px;
  cursor: pointer;
}

.text-form {
  height: 35px;
}

.pointer {
  cursor: pointer;
}

.error-wrapper {
  width: 100vw;
  height: 100vh;
  padding: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.error-animation-container {
  margin-top: -100px;
  width: 60%;
  max-width: 400px;
}

.error-description {
  margin-bottom: 20px;
  font-size: 1.1rem;
  font-weight: 500;
  text-align: center;
}

.eventhound_background {
  background-image: linear-gradient(
    165deg,
    var(--primary),
    var(--primary-dark),
    var(--primary-dark),
    var(--secondary-light)
  );
}

.insatgram_background {
  background-image: linear-gradient(90deg, #ee582c, #d1175e);
}

.facebook_background {
  background-image: linear-gradient(45deg, #394499, #337eb9);
}
