.card-features-container {
  width: 100%;
  padding: 0 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.business-feature-container {
  min-width: 80px;
  min-height: 100px;
  padding: 5px;
  margin: 0 5px 5px 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  box-shadow: 1px 1px 2px rgba(10, 10, 10, 0.8);
  cursor: pointer;
}

.business-feature-container-theme {
  border: 1px solid var(--text-dark);
  color: var(--text-dark);
}

.business-feature-container-white {
  color: rgb(80, 80, 80);
  background-color: rgb(247, 247, 247);
}

.business-feature-selected {
  border: none;
  color: var(--stay-white);
  border: 1px solid var(--primary);
  background-image: linear-gradient(
    var(--primary),
    var(--primary-dark),
    var(--primary)
  );
  box-shadow: inset 1px 1px 2px rgba(10, 10, 10, 0.8);
}

.business-feature-svg {
  width: 35px;
  height: 35px;
}

.business-feature-svg > svg {
  fill: var(--text-dark);
}

.business-signup-feature-svg > svg {
  fill: rgb(80, 80, 80);
}

.business-feature-container-white > div > svg {
  fill: rgb(80, 80, 80);
}

.business-feature-svg-selected > svg {
  fill: var(--stay-white) !important;
}

.business-feature-text {
  text-align: center;
}
