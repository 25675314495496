.no-posted-ad-details {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.no-posted-ad-details-text {
  width: calc(100% - 20px);
  margin-bottom: 15px;
  font-size: 1.2rem;
  text-align: center;
  color: var(--text-dark);
}
