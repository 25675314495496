.scheduled-post-desc {
  width: 100%;
  font-size: 1.2rem;
  font-weight: 600;
  text-align: left;
  color: var(--stay-white);
}

.scheduled_post_icons_wrapper {
  margin-left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.scheduled_post_icons_wrapper div:nth-child(2) {
  margin-left: 8px;
}

.scheduled_post_icon_container {
  width: 22px;
  height: 22px;
  fill: var(--stay-white);
}

.scheduled-post-date-time {
  width: 100%;
  font-size: 1.2rem;
  font-weight: 600;
  text-align: left;
  color: var(--stay-white);
}
