.confirm-button-wrapper {
  width: 100%;
  position: fixed;
  bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.confirm-metadata-conatiner {
  width: 90%;
  max-width: 500px;
  padding: 5px 15px;
  margin-bottom: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  box-shadow: 0px 1px 3px rgba(0,0,0,0.1);
}

.confirm-metadata-conatiner-warning {
  border: 1px solid rgb(255, 204, 0);
  background-color: rgba(255, 204, 0, 0.85);
}

.confirm-metadata-conatiner-noitce {
  border: 1px solid rgb(255, 140, 0);
  background-color: rgba(255, 140, 0, 0.85);
}

.confirm-metadata-text {
  text-align: center;
  font-weight: 600;
  color: rgb(40,40,40);
}

.confirm-button-container {
  width: 90%;
  max-width: 500px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}