.listing-content-container {
  width: calc(100% - 20px);
  margin: 0 0 25px 10px;
  padding: 15px 10px;
  position: relative;
  border-radius: 6px;
  background-color: rgba(247, 247, 247, 0.95);
  background-color: var(--background-opacity);
  box-shadow: 0 0 4px rgba(10, 10, 10, 0.8);
  overflow: hidden;
}

.listing-header-container {
  width: calc(100% + 20px);
  height: 100%;
  min-height: 50px;
  margin-left: -10px;
  padding: 10px 10px 8px 10px;
  position: relative;
  top: -16px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px 5px 0 0;
  box-shadow: 0 3px 15px rgba(10, 10, 10, 0.8);
  z-index: 2;
}

.listing-header-container-starting {
  background: linear-gradient(var(--backgroundColor-secondary), var(--backgroundColor), var(--backgroundColor-secondary));
}

.listing-header-container-active {
  background-image: linear-gradient( var(--primary), var(--primary-dark), var(--primary));
}

.listing-header-container-ending {
  background-image: linear-gradient(var(--secondary), var(--secondary-dark), var(--secondary));
}

.listing-header-container-ended {
  background: linear-gradient(#4a4d4e, #323435, #4a4d4e);
}

.listing-spacing {
  margin-top: 20px;
}

.lisitng-bookmark-container {
  width: 20px;
  height: 30px;
  position: absolute;
  top: -10px;
  left: 13px;
  transition: all 250ms ease-in-out;
  transition-delay: 250ms;
  cursor: pointer;
  z-index: 1;
}

.active-bookmark {
  transform: translateY(10px);
  fill: var(--stay-white);
}

.active-bookmark-dark {
  fill: var(--text-dark);
}

.inactive-bookmark {
  fill: none;
  stroke-width: 2px;
  stroke: var(--stay-white);
}

.inactive-bookmark-dark {
  stroke: var(--text-dark);
}

.listing-report-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
}

.listing-report-text {
  padding-left: 7px;
  font-size: 0.8rem;
}

.listing-flagged-container {
  width: 15px;
  height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  stroke: var(--secondary);
  stroke-width: 2px;
  fill: none;
}

.listing-flagged-container-true {
  fill: var(--secondary);
}

.listing-time-left {
  margin-left: 7px;
  font-size: 1.1rem;
}

.listing-sub-content-container {
  padding-top: 13px;
}

.listing-start-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  clear: both;
}

.listing-split-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  clear: both;
}

.listing-center-content {
  width: 100%;
  padding: 0 5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  clear: both;
}

.listing-title {
  width: 100%;
  margin: -5px 0 10px 0;
  text-align: center;
  font-size: 1.3rem;
  font-weight: 400;
}

.listing-title-main {
  max-width: calc(100% - 100px);
  text-align: center;
  font-size: 1.2rem;
  font-weight: 700;
  letter-spacing: 0.08rem;
  color: var(--stay-white);
}

.listing-title-main-dark {
  color: var(--text-dark);
}

.listing-description-main {
  padding: 0 10px;
  font-size: 1.1rem;
}

.checked-in-popup-container {
  max-height: 0;
  margin-top: 0;
  padding: 0;
  font-size: 15px;
  font-weight: 600;
  border-radius: 4px;
  color: var(--text-dark);
  background-color: var(--backgroundColor-secondary);
  box-shadow: inset 0 0 4px rgba(10, 10, 10, 0.6);
  transition: all 250ms ease-in-out 300ms;
  overflow: hidden;
}

.checked-in-popup-container-open {
  max-height: 700px;
  margin-top: 10px;
  padding: 10px;
}

.checked-in-thanks {
  margin-bottom: -5px;
  font-size: 1.3rem;
  text-align: center;
}

.checked-in-info {
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
}

.check-in-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.check-in-title {
  text-align: left;
  font-size: 1.2rem;
}

.listing-business-hours-container {
  width: 100%;
  max-width: 420px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.listing-day-open {
  min-width: 35px;
  padding: 2px 7px;
  text-align: center;
  border-radius: 4px;
  color: var(--text-dark);
  background-color: var(--backgroundColor);
  border: 1px solid var(--text-light);
  box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.6);
}

.listing-day-starting {
  border: 1px solid var(--text-medium);
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.6);
}

.listing-day-active {
  border: 1px solid var(--primary);
  box-shadow: inset 0 0 5px var(--primary);
}

.listing-day-ending {
  border: 1px solid var(--secondary);
  box-shadow: inset 0 0 5px var(--secondary);
}

.listing-day-ended {
  border: 1px solid var(--text-light);
  box-shadow: inset 0 0 5px var(--text-light);
}

.listing-day-closed {
  color: var(--text-light);
}

.lesson-category-container {
  min-width: 100px;
  height: 100px;
  padding: 10px;
  margin: 2px 5px 5px 2px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background-color: var(--backgroundColor);
  cursor: pointer;
}

.lesson-category-starting {
  border: 1px solid var(--text-medium);
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.6), 1px 1px 2px rgba(0, 0, 0, 0.4);
}

.lesson-category-active {
  border: 1px solid var(--primary);
  box-shadow: inset 0 0 5px var(--primary), 1px 1px 2px rgba(0, 0, 0, 0.4);
}

.lesson-category-ending {
  border: 1px solid var(--secondary);
  box-shadow: inset 0 0 5px var(--secondary), 1px 1px 2px rgba(0, 0, 0, 0.4);
}

.lesson-category-ended {
  border: 1px solid var(--text-light);
  box-shadow: inset 0 0 5px var(--text-light), 1px 1px 2px rgba(0, 0, 0, 0.4);
}

.listing-category-icon {
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--text-dark);
  fill: var(--text-dark);
}

.listing-category-text {
  margin-top: 2px;
  font-size: 1.1rem;
  white-space: nowrap;
}

.lesson-features-wrapper {
  padding: 0;
  margin: 0;
  margin: 15px 0 5px 0;
}

.lesson-features-container {
  width: 100%;
  height: 38px;
  padding: 2px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.listing-type-icon {
  width: 20px;
  height: 20px;
  margin-right: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  fill: var(--stay-white);
}

.lesson-category-and {
  margin: 0 10px;
}

.listing-business-image {
  width: 150px;
  height: 150px;
  margin-right: 10px;
  border-radius: 4px;
  box-shadow: 0 0 2px rgba(10, 10, 10, 0.8);
  float: left;
}

.listing-contact-wrapper {
  width: 100%;
  height: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.listing-contact-container {
  padding: 5px 3px;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.listing-contact-container-border-left {
  border-left: 1px solid var(--skeleton-opacity);
}

.listing-contact-svg-container {
  width: 24px;
  height: 24px;
  fill: var(--text-dark);
}

.listing-contact-text {
  margin-top: 5px;
  font-size: 1rem;
  white-space: nowrap;
  color: var(--text-dark);
}

.listing-hr {
  width: calc(100% + 20px);
  margin: 5px 0 0 -10px;
  border-bottom: 1px solid var(--backgroundColor-secondary);
}

.listing-business-info-conatiner {
  margin-bottom: -6px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
}

.listing-business-name {
  font-size: 1.3rem;
}

.listing-business-info-svg {
  width: 13px;
  max-width: 13px;
  margin: 2px 0 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.listing-date-text {
  margin: 0 0 5px 5px;
}

.redemtion-button-code-button {
  width: 100%;
  max-width: 350px;
  height: 40px;
  display: grid;
  place-items: center;
  border-radius: 4px;
  box-shadow: 2px 2px 4px rgba(40, 40, 40, 0.4);
  cursor: pointer;
}

.redemtion-button-code-button:active {
  color: var(--text-dark);
}

.redemtion-button-code-text {
  font-size: 1.2rem;
  letter-spacing: 0.05rem;
  font-weight: 600;
}

.listing-fill-starting > svg {
  fill: var(--text-medium);
}

.listing-fill-active > svg {
  fill: var(--primary);
}

.listing-fill-ending > svg {
  fill: var(--secondary);
}

.listing-fill-ended > svg {
  fill: var(--text-medium);
}

.listing-background-starting {
  background-color: var(--text-medium);
  background-image: linear-gradient(var(--text-light), var(--text-medium), var(--text-light));
}

.listing-background-active {
  background-image: linear-gradient(90deg, var(--primary-dark), var(--primary-light));
}

.listing-background-ending {
  background-image: linear-gradient(90deg, var(--secondary-dark), var(--secondary));
}

.listing-background-ended {
  background-color: #323435;
}

.listing-border-starting {
  border: 1px solid var(--text-medium);
}

.listing-border-active {
  border: 1px solid var(--primary);
}

.listing-border-ending {
  border: 1px solid var(--secondary);
}

.listing-border-ended {
  border: 1px solid var(--text-light);
}

.listing-day-white {
  color: var(--stay-white);
}

.listing-content-title {
  width: 100%;
  margin-bottom: 10px;
  font-size: 1.3rem;
  text-align: center;
}
