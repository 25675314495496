.mapNoResultsInfoWrapper {
  width: 100%;
  padding: 0px 10px 10px 10px;
  position: absolute;
  bottom: 0px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  z-index: 1;
}

.mapNoResultsIcon {
  width: 35px;
  height: 35px;
  margin-right: 10px;
}

.mapNoResultsIcon > svg {
  fill: var(--stay-white)
}

.mapNoResultsTextWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.mapNoResultsTitle {
  font-size: 1.2rem;
  font-weight: 500;
  color: var(--stay-white);
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
}

.mapNoResultsDescriptionContainer {
  padding-top: 5px;
  border-top: 1px solid var(--stay-white);
}

.mapNoResultsDescription {
  font-size: 1.1rem;
  font-weight: 400;
  color: var(--stay-white);
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
}
