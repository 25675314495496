.profile-listing-content-container {
  width: calc(100% - 20px);
  margin: 0 0 25px 10px;
  padding: 15px 10px;
  position: relative;
  border-radius: 6px;
  background-color: rgba(247, 247, 247, 0.95);
  background-color: var(--background-opacity);
  box-shadow: 0 0 4px rgba(10, 10, 10, 0.8);
}

.profile-ads-pagination {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile-ads-pagination-arrow-container {
  width: 20px;
  float: right;
  display: flex;
}

.profile-ads-pagination-arrow-container > svg {
  fill: var(--text-dark);
}

.profile-ads-pagination-number {
  margin: 0 8px;
  font-size: 1.2rem;
}

.profile-ads-pagination-number-active {
  color: var(--primary);
}

.user-profile-image-container {
  width: 100%;
  height: 75px;
  position: relative;
}

.user-profile-image {
  width: 150px;
  height: 150px;
  position: absolute;
  top: -85px;
  left: calc(50% - 75px);
  border-radius: 100%;
  box-shadow: 0 0 6px rgba(10, 10, 10, 0.8);
}

.user-search-wrapper-outter {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.user-search-wrapper {
  width: 100%;
  max-width: 450px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.user-search-wrapper-inner {
  width: 100%;
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.user-search-popular-container {
  width: 100%;
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background-image: linear-gradient(var(--primary-light), var(--primary-dark));
  box-shadow: 1px 1px 6px rgba(10, 10, 10, 0.8);
  cursor: pointer;
}

.user-search-container {
  width: 70px;
  height: 70px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background-image: linear-gradient(var(--primary-light), var(--primary-dark));
  box-shadow: 1px 1px 6px rgba(10, 10, 10, 0.7);
  cursor: pointer;
}

.user-search-icon {
 width: 26px;
 height: 26px;
}

.user-search-icon > svg {
  fill: var(--stay-white)
 }

.user-search-text {
  font-weight: 500;
  color: var(--stay-white);
}
