.home-top-wrapper {
  width: 100%;
  position: relative;
}

.home-bottom-wrapper-outer {
  width: 100%;
  padding-top: 45px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  overflow-y: hidden;
}

.home-bottom-wrapper {
  width: calc(100% - 20px);
  max-width: 1120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.home-type-outer-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

.home-type-wrapper {
  width: 100%;
  max-width: 650px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-items: center;
}

.home-type-container {
  width: 100px;
  height: 70px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--background-opacity);
  border-radius: 4px;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.5);
  cursor: pointer;
}

.home-type-container-active {
  background-color: var(--primary);
  box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.7);
}

.home-type-icon {
  width: 30px;
  height: 30px;
  fill: var(--text-dark);
}

.home-type-text {
  font-size: 1rem;
  text-align: center;
}

.home-type-active {
  color: var(--stay-white);
  fill: var(--stay-white);
}

.home-bottom-text-conatiner {
  width: calc(100% - 35px);
  margin: 20px 0px 0px 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  flex-wrap: nowrap;
}

.home-highlight-text {
  font-size: 1rem;
  font-weight: 700;
}

.home-see-more-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.home-bottom-see-more {
  font-size: 0.85rem;
}

.home-see-more-svg {
  width: 13px;
  margin: 0 0 0 3px;
}

.home-see-more-svg > svg {
  fill: var(--text-dark);
}

.home-profile-svg-container {
  width: 18px;
  height: 18px;
  position: absolute;
  top: 15px;
  right: 15px;
}

.home-profile-svg-container > svg {
  fill: rgb(200, 200, 200);
}

.home-background-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
}

.home-background-image-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: radial-gradient(rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.5));
  box-shadow: inset 0px 0px 22px rgba(0, 0, 0, 0.6);
}

.home-text-container {
  width: 100%;
  position: absolute;
  top: 25%;
}

.home-logo-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.home-icon-container {
  width: 12%;
  max-width: 60px;
  fill: var(--stay-white);
}

.home-name-container {
  width: 60%;
  max-width: 400px;
  margin: -4px 0px;
  fill: var(--stay-white);
}

.home-text {
  font-size: 1.2rem;
  text-align: center;
  color: var(--stay-white);
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.8);
}

.home-image {
  min-width: 250px;
  width: 50%;
  max-width: 350px;
  margin-left: -5px;
}

.home-logo-image {
  width: 6%;
  max-width: 50px;
}

.home-logo-image > svg {
  fill: var(--stay-white);
}

.home-name-image {
  width: 60%;
  max-width: 300px;
  margin-left: 8px;
}

.home-name-image > svg {
  fill: var(--stay-white);
}

@media screen and (min-width: 700px) {
  .home-text {
    font-size: 1.8rem;
    font-weight: 500;
  }
}

.home-search-wrapper {
  width: 100%;
  height: 50px;
  position: absolute;
  bottom: -25px;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.home-search-slideup-wrapper {
  width: 80%;
  max-width: 1100px;
  height: 100%;
  max-height: 50%;
  margin-left: 10%;
  position: fixed;
  bottom: 0;
  right: 50%;
  transform: translate(50%, 0);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: var(--backgroundColor);
  opacity: 0;
  z-index: -1;
  transition: all 0.3s ease;
}

.home-search-slideup-wrapper-focused {
  width: 100%;
  max-height: 100%;
  margin-left: 0;
  opacity: 1;
  z-index: 11;
  overflow: hidden;
}

.home-search-slideup-background {
  width: 100vw;
  height: 100vh;
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  animation-name: fade;
  animation-duration: 250ms;
  animation-fill-mode: forwards;
  z-index: 10;
}

@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.home-search-container {
  width: 85%;
  max-width: 400px;
  height: 55px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 4px;
  transition: all 0.2s ease;
  box-shadow: 2px 2px 4px rgba(40, 40, 40, 0.4);
}

.home-search-scroll-text-wrapper {
  width: calc(100% - 43px);
  height: 20px;
  position: absolute;
  left: 43px;
  overflow: hidden;
}

.home-search-scroll-text-conatiner {
  width: 100%;
  animation-duration: 7000ms;
  animation-fill-mode: forwards;
  animation-name: slideUp;
}

@keyframes slideUp {
  from {
    transform: translateY(0);
  }
  40% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-22px);
  }
  75% {
    transform: translateY(-22px);
  }
  to {
    transform: translateY(-22px);
  }
}

.home-search-scroll-text {
  font-size: 1.06rem;
  line-height: 22px;
  color: #7c7c7c;
}

.home-search-container-focused {
  width: 100%;
  max-width: 100%;
  height: 100%;
  transform: translateY(-280px);
  transition-delay: 0.45s;
}

@media screen and (min-width: 1050px) {
  .home-search-slideup-wrapper {
    bottom: 40%;
    transform-origin: right bottom;
    transform: scale(0) translate(50%, 0);
  }
  .home-search-slideup-wrapper-focused {
    min-height: 325px;
    max-height: 40%;
    border-radius: 10px;
    box-shadow: 2px 2px 10px rgba(40, 40, 40, 0.6);
    transform: scale(1) translate(50%, 0);
  }
  .home-search-container-focused {
    max-width: 1050px;
    transform: translateY(-90px);
  }
}

.home-input-slideup-top-container {
  width: 100%;
  margin-top: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
}

.home-input-slideup-top-left-wrapper {
  width: calc(100% - 65px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  flex-wrap: nowrap;
}

.home-input-slideup-top-left-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: nowrap;
  border-bottom: 1px solid var(--backgroundColor-secondary);
}

.home-input-type-container {
  margin-bottom: 10px;
}

.home-input-type-container > div {
  height: 35px;
  padding: 0px 10px;
  margin-left: 0px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
}

.home-search-slideup-container {
  width: calc(100% - 35px);
  padding-left: 15px;
  height: 40px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: transparent;
}

.home-search-svg-container {
  width: 20px;
  height: 20px;
  min-width: 20px;
  min-height: 20px;
}

.home-search-svg-container > svg {
  fill: #828383;
}

.home-back-svg-container {
  width: 23px;
  height: 23px;
  min-width: 23px;
  min-height: 23px;
  margin-top: -5px;
}

.home-back-svg-container > svg {
  fill: var(--text-dark);
}

.home-map-svg-container {
  width: 55px;
  height: 50px;
  min-width: 55px;
  min-height: 50px;
  padding: 0 11px;
  border-left: 1px solid var(--backgroundColor-secondary);
}

.home-map-svg-container > svg {
  fill: var(--text-dark);
}

.home-search-input {
  width: calc(100% - 55px);
  height: 100%;
  padding: 5px;
  background-color: transparent;
  border: none;
}

.home-search-slideup-input {
  width: calc(100% - 55px);
  height: 100%;
  padding: 5px;
  color: var(--text-dark);
  background-color: transparent;
  border: none;
}

.home-search-input-focused {
  color: var(--text-dark);
}

.home-search-input::placeholder {
  fill: var(--text-dark);
}

.home-input-slideup-bottom-container {
  width: calc(100% - 50px);
  margin-top: 10px;
}
