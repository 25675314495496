.header-el-wrapper {
  width: 100%;
  background-color: rgb(247, 246, 246);
  background-color: var(--backgroundColor);
  border-bottom: 1px solid rgb(236, 236, 236);
  border-bottom: 1px solid var(--backgroundColor-secondary);
}

.header-el-shadow {
  box-shadow: 0 0 4px rgba(10, 10, 10, 0.6);
}

.header-title-outer-wrapper-MobileHeader {
  width: calc(100% - 20px);
  margin-left: 10px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header-title-inner-wrapper-MobileHeader {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title-EventHound-logo {
  width: auto;
  height: 27px;
}

.mobile-header-title {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 102;
}

.header-title-business {
  font-size: 1.3rem;
  font-weight: 500;
  text-align: center;
  color: var(--text-mdark);
}

#mobile-header-search {
  position: fixed;
  top: 40px;
  left: 0;
  z-index: 101;
  transition: transform 280ms ease-in-out;
}

#mobile-header-filters {
  position: fixed;
  top: 80px;
  left: 0;
  z-index: 100;
  transition: transform 280ms ease-in-out;
}

.header-logo-MobileHeader {
  width: 180px;
  height: 28px;
  margin-top: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.header-logo-MobileHeader > svg {
  fill: var(--text-dark);
}

.header-user-wrapper-MobileHeader {
  width: 200px;
}

.header-profile-container-MobileHeader {
  width: 40px;
}

.header-profile-image-container-MobileHeader {
  height: 25px;
  width: 25px;
  float: right;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  cursor: pointer;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  image-orientation: from-image;
  border: 1px solid var(--text-medium);
  overflow: hidden;
}

.header-profile-svg-container-MobileHeader {
  height: 20px;
  width: 20px;
  float: right;
  display: flex;
  fill: hsl(197, 99%, 40%);
  fill: var(--text-dark);
  justify-content: center;
  align-items: center;
}

.header-search-outer-wrapper-MobileHeader {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid rgb(236, 236, 236);
  border-bottom: 1px solid var(--backgroundColor-secondary);
}

.header-search-inner-wrapper-MobileHeader {
  width: 100%;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
}

.filter-button-container {
  width: 100%;
  min-width: 65px;
  height: 22px;
  min-height: 22px;
  padding: 4px 4px 5px 4px;
  margin-left: 5px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid var(--text-medium);
  border-radius: 4px;
  overflow: hidden;
}

.filter-button-icon-container {
  width: 15px;
  min-width: 15px;
  height: 15px;
  margin-left: 10px;
  fill: var(--text-dark);
}

.filter-button-text-container {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
}

.filter-button-text {
  margin-left: 10px;
  color: var(--text-dark);
}

.filter-button-text-active {
  margin-left: 5px;
  white-space: nowrap;
  color: var(--primary);
}

.filter-reset-container {
  width: 90px;
  min-width: 90px;
  height: 21px;
  min-height: 21px;
  padding: 4px 4px 5px 4px;
  margin-left: 5px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: var(--text-dark);
  fill: var(--backgroundColor);
  border-radius: 4px;
}

.filter-reset-icon-container {
  width: 15px;
  min-width: 15px;
  height: 15px;
  margin-left: 10px;
  fill: var(--backgroundColor);
}

.filter-reset-text {
  margin-right: 3px;
  margin-left: 10px;
  color: var(--backgroundColor);
}

.header-svg-container-MobileHeader {
  min-width: 30px;
  height: 25px;
  padding: 0 3px;
  cursor: pointer;
}

.header-svg-container-MobileHeader > svg {
  fill: var(--text-medium);
}

.header-back-container-MobileHeader {
  width: 40px;
  z-index: 1;
}

.header-back-MobileHeader {
  width: 26px;
  height: 20px;
  float: left;
}

.header-back-MobileHeader > svg {
  fill: var(--text-dark);
}

.header-map-container-MobileHeader {
  max-width: 35px;
  max-height: 30px;
  padding: 3px 3px;
  margin-left: 5px;
}

.header-map-container-MobileHeader> svg {
  fill: var(--text-dark);
}

.filter-outer-wrapper-MobileHeader {
  display: flex;
  justify-content: center;
  align-items: center;
}

.filter-inner-wrapper-MobileHeader {
  height: 30px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  overflow-x: scroll;
}

.filter-small-mobile {
  display: flex;
  flex: 1; 
}

.filter-small-mobile > div {
  flex: 1; 
}

.filter-large-mobile {
  display: none;
}

@media (min-width: 1200px){
  .filter-large-mobile {
    display: flex;
  }
  .filter-small-mobile {
    display: none;
  }
}

.filter-spacer {
  width: 5px;
  min-width: 5px;
  max-width: 5px;
  height: 100%;
}

.filter-text-MobileHeader {
  margin: -2px 0 0 5px;
  font-weight: 400;
  color: var(--primary);
}

.filter-date-container {
  min-width: 163px;
  margin-left: 5px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid var(--text-medium);
  border-radius: 4px;
  overflow: hidden;
}

.filter-date-svg-container {
  height: 15px;
  width: 15px;
  margin: 0 5px;
  fill: var(--text-dark);
}

.filter-date-input {
  width: 136px;
  height: 20px;
  padding: 1px 3px;
  background: var(--backgroundColor);
  background-color: var(--backgroundColor);
  background-image: linear-gradient(
    var(--backgroundColor),
    var(--backgroundColor)
  );
  border: none;
  border-style: none;
  color: var(--text-dark);
}

.title-bascity-logo {
  width: 20px;
}

.logout-drawer-content-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: space-around;
}

.filter-select-container {
  width: 100%;
  padding-left: 5px;
}

.drawer-filters-wrapper {
  display: grid;
  row-gap: 15px;
}

.drawer-filters-wrapper > div {
  width: 90vw !important;
  max-width: 450px;
  height: 35px;
  padding: 0px 10px;
  box-shadow: 2px 2px 4px rgba(0,0,0,0.5);
}

.drawer-filters-container {
  width: 90px;
  min-width: 90px;
  height: 21px;
  min-height: 21px;
  padding: 4px 4px 5px 4px;
  margin-left: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  fill: var(--stay-white);
  border-radius: 4px;
}

.filter-reset-container-secondary {
  background-color: var(--secondary);
}

.drawer-filters-close-container {
  background-color: var(--primary);
}

.drawer-filters-toggle-container {
  width: 90%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.drawer-filters-toggle-title {
  margin-left: 10px;
  font-size: 1.2rem;
}

.drawer-filters-toggle-text {
  width: 90%;
  margin: 8px 0px 15px;
}
