.modal-page-click-overlay {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.65);
  background-color: var(--opacity-dark);
  visibility: hidden;
}

.modal-wrapper {
  width: 400px;
  max-width: 90%;
  min-height: 250px;
  padding: 15px 15px 20px 15px;
  position: fixed;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  border-radius: 5px;
  color: #323435;
  color: var(--text-dark);
  background-color: rgb(247, 246, 246);
  background-color: var(--backgroundColor);
  box-shadow: 2px 2px 10px #333;
  overflow: hidden;
  visibility: hidden;
}

.modal-logo-wrapper {
  width: 60%;
  max-width: 200px;
  height: 30px;
  min-height: 36px;
  margin: 8px 0;
  fill: var(--text-dark);
}

.modal-logo-title {
  width: 100%;
  margin-bottom: 15px;
  font-size: 1.2rem;
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
}

.modal-text {
  width: 90%;
  margin-bottom: 20px;
  flex-shrink: 0;
  font-size: 1.2rem;
  text-align: center;
}

.modal-button-wrapper {
  width: 90%;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.modal-login-page-click-overlay {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgb(0, 0, 0);
  object-fit: cover;
  object-position: 50% 50%;
  -webkit-filter: blur(2px);
  -moz-filter: blur(2px);
  -o-filter: blur(2px);
  -ms-filter: blur(2px);
  filter: blur(2px);
  visibility: hidden;
}
