.expand-conatiner {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}

.expand-see-more {
  margin-left: 10px;
  color: var(--primary);
}

.expand-arrow {
  width: 15px;
  height: 15px;
  margin-left: 10px;
  fill: var(--primary);
  transform: rotate(-90deg);
  transition: transform 100ms;
}

.expand-arrow-active {
  transform: rotate(90deg);
}
