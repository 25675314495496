.listing-skeleton-card-image-container {
  width: calc(100% - 20px);
  margin: 0px 0px 25px 10px;
  padding-bottom: 75%;
  border-radius: 10px;
  box-shadow: 0 0 4px rgba(10, 10, 10, 0.8);
  background-color: rgb(236, 236, 236);
  background-color: var(--backgroundColor);
  background-image: linear-gradient(
    90deg,
    rgb(247, 247, 247) 22%,
    rgba(210, 210, 210, 0.6) 30%,
    rgb(247, 247, 247) 38%
  );
  background-image: linear-gradient(
    90deg,
    var(--backgroundColor) 22%,
    var(--skeleton-opacity) 30%,
    var(--backgroundColor) 38%
  );
  background-size: 200%;
  animation: skeletonAnimate 1.4s infinite linear;
  -webkit-animation-duration: 1.4s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-name: skeletonAnimate;
  -webkit-animation-timing-function: linear;
  overflow: hidden;
}

.listing-skeleton-card-container {
  width: calc(100% - 20px);
  height: 240px;
  margin: 0px 0px 25px 10px;
  border-radius: 10px;
  box-shadow: 0 0 4px rgba(10, 10, 10, 0.8);
  background-color: rgb(236, 236, 236);
  background-color: var(--backgroundColor);
  background-image: linear-gradient(
    90deg,
    rgb(247, 247, 247) 22%,
    rgba(210, 210, 210, 0.6) 30%,
    rgb(247, 247, 247) 38%
  );
  background-image: linear-gradient(
    90deg,
    var(--backgroundColor) 22%,
    var(--skeleton-opacity) 30%,
    var(--backgroundColor) 38%
  );
  background-size: 200%;
  animation: skeletonAnimate 1.4s infinite linear;
  -webkit-animation-duration: 1.4s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-name: skeletonAnimate;
  -webkit-animation-timing-function: linear;
  overflow: hidden;
}

@-webkit-keyframes skeletonAnimate {
  from {
    background-position: 100%;
  }
  to {
    background-position: -100%;
  }
}

@keyframes skeletonAnimate {
  from {
    background-position: 100%;
  }
  to {
    background-position: -100%;
  }
}

.listing-skeleton-left-container {
  width: 95%;
  margin: 15px 0 0 2.5%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.listing-skeleton-split-container {
  width: 90%;
  margin: 15px 0 0 2.5%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.listing-skeleton-el {
  margin-top: 15px;
  border-radius: 5px;
  background-color: rgb(210, 210, 210);
  background-color: var(--skeleton);
}

.listing-skeleton-title-container {
  width: 250px;
  height: 20px;
  margin-bottom: 5px;
}

.listing-skeleton-large-container {
  width: 300px;
  height: 12px;
}

.listing-skeleton-medium-container {
  width: 200px;
  height: 12px;
}

.listing-skeleton-small-container {
  width: 100px;
  height: 12px;
}
