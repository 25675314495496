.nav-outer-wrapper-MobileNav {
  width: 100%;
  height: 75px;
  padding-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background-color: rgb(247, 246, 246);
  background-color: var(--backgroundColor);
  position: fixed;
  bottom: -1px;
  left: 0;
  transition: transform 280ms ease-in-out;
  z-index: 10;
}

.nav-inner-wrapper-MobileNav {
  width: 100%;
  max-width: 800px;
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
}

.nav-item-container-MobileNav {
  width: 100%;
  max-width: 120px;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-top: 2px solid var(--backgroundColor);
  cursor: pointer;
}

.nav-item-container-active {
  width: 100%;
  height: 10px;
  position: absolute;
  top: 0;
  border-top: 1px solid var(--backgroundColor);
  background-image: linear-gradient(var(--primary), var(--backgroundColor) 70%);
  border-radius: 0px 0px 100px 100px;
  opacity: 0.6;
}

.nav-svg-container-MobileNav {
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-svg-container-MobileNav > svg {
  fill: var(--text-dark);
}

.nav-text-MobileNav {
  font-size: 0.95rem;
  font-weight: 600;
  color: var(--text-dark);
}

.nav-item-isActive-MobileNav {
  border-top: 2px solid var(--primary);
  /* transform: scale(1.1); */
}

.nav-item-isActive-MobileNav > .nav-svg-container-MobileNav > svg {
  fill: var(--primary);
}

.nav-item-isActive-MobileNav > .nav-text-MobileNav {
  color: var(--primary);
}

@media only screen and (min-width: 1000px) {
  .nav-svg-container-MobileNav {
    width: 35px;
    height: 35px;
  }

  .nav-text-MobileNav {
    font-size: 1.1rem;
  }
}
