.card-title-SignUp {
  width: 100%;
  margin-bottom: 5px;
  text-align: center;
  color: #323435;
  color: var(--text-dark);
}

.hour-selector-wrapper-SignUp {
  width: 95%;
  max-width: 500px;
  min-height: 50px;
  margin-left: 5%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.hour-selector-wrapper-SignUp > * {
  margin-right: 5%;
}

.signup-toggle-split-container {
  width: 95%;
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.signup-toggle-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.business-time-selector-container {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.signup-toggle-section {
  min-height: 40px;
  margin-top: 12px;
  padding-top: 12px;
  color: var(--text-dark);
}

.signup-toggle-text-container {
  width: 100%;
  margin-bottom: 5px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
}

.signup-toggle-subsection {
  width: 100%;
  max-width: 550px;
  max-height: 0;
  display: flex;
  flex-direction: column;
  color: var(--text-dark);
  overflow: hidden;
  transition: max-height 250ms ease-in;
}

.signup-toggle-subsection > * {
  margin-top: 10px;
}

.signup-toggle-subsection-open {
  max-height: 800px;
}

.signup-toggle-subsection-svg-container {
  width: 15px;
  height: 15px;
  fill: var(--text-dark);
  transform: rotate(-90deg);
  transition: transform 100ms;
}

.signup-toggle-subsection-svg-container-open {
  transform: rotate(90deg);
}

@media only screen and (max-width: 400px) {
  .hour-selector-wrapper-SignUp > * {
    margin-right: 0px;
  }
}
