.sign-up-wrapper {
  position: relative;
}

.sign-up-content-wrapper {
  min-height: 500px;
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  transition-property: all;
  -webkit-transition-property: all;
  transition-duration: 0.8s;
  -webkit-transition-duration: 0.8s;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
  -webkit-transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition-delay: 0;
  -webkit-transition-delay: 0;
  box-shadow: 0 2px 8px 0 rgba(22, 1, 40, 0.7);
  background-color: var(--backgroundColor);
}

.sign-up-content-wrapper > div:first-child {
  margin-top: 30px;
}

.sign-up-card-front {
  transition-duration: 0.6s;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
  -webkit-transition-duration: 0.6s;
  -webkit-transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
  z-index: 1;
}

.sign-up-card-back {
  top: 65px;
  opacity: 0.7;
  transform: scale(0.85);
  -webkit-transform: scale(0.85);
}

.profile-selector-container {
  width: 80%;
  height: 60px;
  position: absolute;
  top: -33px;
  left: 10%;
  border-radius: 6px;
  background-color: var(--text-medium);
  box-shadow: 0 4px 8px rgba(10, 10, 10, 0.7);
  overflow: hidden;
  z-index: 99;
}

.login-forgot-text-Profile {
  margin-top: -5px;
  cursor: pointer;
}

.login-forgot-text-Profile:hover {
  color: var(--primary);
}

.sign-up-container {
  width: 100%;
  max-width: 800px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}

.sign-up-container-column {
  width: 100%;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.sign-up-container > * {
  margin: 8px;
  cursor: pointer;
}

.sign-up-container-column > * {
  margin: 8px;
  cursor: pointer;
}

.sign-up-container > button {
  width: 220px;
  height: 48px;
}

.login-basicty-icon-wrapper {
  height: 25px;
  width: 25px;
  min-height: 25px;
  min-width: 25px;
  fill: var(--stay-white);
}

.sign-up-container > div {
  height: 48px;
  width: 220px;
  border-radius: 2px;
  background-color: black;
  box-shadow: 2px 2px 4px rgba(40, 40, 40, 0.4);
  overflow: hidden;
}

.sign-up-container > button {
  box-shadow: 2px 2px 4px rgba(40, 40, 40, 0.4) !important;
}

.facebook-button {
  width: 220px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  font-family: Helvetica, sans-serif;
  text-transform: uppercase;
  border-radius: 2px;
  border: none;
  color: #fff;
  background-color: #4c69ba;
  box-shadow: 2px 2px 4px rgba(40, 40, 40, 0.4);
  transition: none !important;
  cursor: pointer;
}

.facebook-button > i {
  font-size: 1.3rem;
  margin-right: 10px;
}

.sign-up-video-container {
  width: 100%;
  height: 100%;
  margin-bottom: 10px;
}