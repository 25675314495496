.switch-button-container {
  width: 100%;
  height: calc(100% - 5px);
  position: "absolute";
  top: 0;
  left: 0;
  flex: 0 ;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  flex-wrap: nowrap;
  background-color: var(--backgroundColor);
}

.switch-button-button {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.switch-button-selector {
  font-size: 1.2rem;
  font-weight: 600;
  color: var(--text-dark);
}

.switch-button-isSelected {
  font-weight: 700;
  color: var(--primary);
}

.switch-button-highlight {
  width: 50%;
  height: 100%;
  background-color: var(--primary);
  transition: transform 250ms ease-out;
}

.switch-button-highlight-isSelected {
  transform: translateX(100%);
}

.listing-selector-container {
  width: calc(100% - 50px);
  height: 45px;
  margin-bottom: 10px;
  overflow: hidden;
}