.button-wrapper-Button {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 2px;
  color: #f1f1f1;
  color: var(--stay-white);
  box-shadow: 2px 2px 4px rgba(40, 40, 40, 0.4);
  cursor: pointer;
}

.button-svg-container-Button {
  margin-right: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button-svg-container-Button > svg {
  fill: #f1f1f1;
  fill: var(--stay-white);
}

.button-text-Button {
  padding: 0px 5px;
  white-space: nowrap;
}

.button-disabled {
  background-color: var(--backgroundColor);
  box-shadow: inset 0px 0px 5px rgba(40, 40, 40, 0.5);
}

.button-disabled > p {
  color: var(--text-medium);
}

.button-primary {
  background-image: linear-gradient( var(--primary), var(--primary-dark), var(--primary));
}

.button-secondary {
  background-image: linear-gradient(var(--secondary), var(--secondary-dark), var(--secondary));
}
