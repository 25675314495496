.map-container-web {
  width: calc(100% - 70px);
  height: calc(100vh - 115px);
  position: fixed;
  top: 40px;
  left: 70px;
}

.map-container-mobile {
  width: 100%;
  height: calc(100vh - 185px);
  position: fixed;
  top: 111px;
  left: 0;
}

.map-update-container {
  width: 140px;
  height: 30px;
  position: absolute;
  top: 15px;
  left: 50%;
}

.map-update-svg-container {
  height: 20px;
  width: 20px;
}

.map-update-svg-container > svg {
  fill: var(--backgroundColor);
}
