.web-view-wrapper {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  visibility: hidden;
  z-index: 150;
}

.web-view-header {
  width: 100vw;
  height: 40px;
  position: absolute;
  top: 0px;
  left: 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--backgroundColor);
}

.web-view-iframe {
  width: 100vw;
  height: calc(100% - 40px);
  margin-top: 40px;
}