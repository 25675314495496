.skyline-logo {
  fill: none;
  stroke: url(#SVGID_1_);
  stroke-miterlimit: 10;
}

.profile-panda {
  fill: hsl(197, 99%, 40%);
  fill: var(--primary);
}

.profile-glasses {
  fill: hsl(6, 100%, 60%);
  fill: var(--secondary);
}

.yelp-rating-star-outline {
  fill: rgb(60, 60, 60);
  fill: var(--text-dark);
}

.logo-name {
  fill: var(--text-dark);
}

.icon-gradient {
  fill: url(#SVGID_2_);
}

.svg-clip-rule {
  fill-rule: evenodd;
  clip-rule: evenodd;
}

.fill-none {
  fill:none;
}

.logo0{display:none;fill:#FFFFFF;stroke:#000000;stroke-miterlimit:10;}
.logo1{clip-path:url(#SVGID_2_);}
.logo2{clip-path:url(#SVGID_4_);}
.logo3{display:none;}
.logo4{display:inline;}
.logo5{display:inline;clip-path:url(#SVGID_8_);fill:none;stroke:#000000;stroke-width:40;stroke-miterlimit:10;}
.logo6{display:inline;clip-path:url(#SVGID_8_);}

.covid0{fill-rule:evenodd;clip-rule:evenodd;fill:url(#covid_1_);stroke:#3E3F00;stroke-width:0.25;stroke-miterlimit:10;}
.covid1{fill:#2A2900;}
.covid3{font-size:13px;}

.prefix__st11 {
  fill: var(--text-extra-dark);
}

.smallBusines0{display:none;}
.smallBusines1{display:inline;}

.smallBusines2 {
  fill:none;stroke-width:0.1562;stroke-miterlimit:15.625;
}

.smallBusines3 {
  stroke-miterlimit:10;
}

.lgbtq0{fill:#00C0F4 !important;}
.lgbtq1{fill:#004DFF !important;}
.lgbtq2{fill:#FFED00 !important;}
.lgbtq3{fill:#FF8C00 !important;}
.lgbtq4{fill:#008026 !important;}
.lgbtq5{fill:#750787 !important;}
.lgbtq6{fill:#E40303 !important;}

.postFlow0{fill:none;stroke-miterlimit:10;}
.postFlow1{font-family:'ArialMT';}
.postFlow2{stroke:none;font-size:6px;}