.create-post-business-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;
}

.create-post-business-button-warpper {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}

.create-post-business-checkbox-container {
  margin: 15px;
}

.create-post-business-button {
  width: 160px;
  min-width: 160px;
  height: 60px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
}

.create-post-business-button-hover:hover {
  box-shadow: 0px 1px 4px rgba(0,0,0,0.7);
  cursor: pointer;
}

.create-post-business-button-2 {
  display: none;
}

@media only screen and (max-width: 550px) {
  .create-post-business-wrapper {
    height: 200px;
    flex-direction: column;
  }

  .create-post-business-button-warpper {
    flex-direction: row;
  }

  .create-post-business-button-1 {
    display: none;
  }

  .create-post-business-button-2 {
    display: flex;
  }
}

.create-post-selector-container {
  width: 85%;
  height: 45px;
  margin: 0px 0px 10px 5%;
  overflow: hidden;
}

.create-post-business-button-text {
  margin: 0px 0px 2px 5px;
  font-size: 1.3rem;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  color: var(--stay-white);
}

.create-post-connect-container {
  width: 100%;
  margin-top: 25px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.create-post-social-title-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  border-radius: 22px;
}

.create-post-icon-container {
  width: 24px;
  height: 24px;
  fill: #EE582C;
}

.create-post-title-text {
  margin: 0px 10px;
  font-size: 1.4rem;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  color: #EE582C;
}

.create-post-instgram-arrow-inner {
  width: 18px;
  height: 20px;
  transform: rotate(90deg);
  fill: #EE582C;
  stroke:#EE582C;
  stroke-width: 2px;
}

.create-post-arrow-inner {
  width: 18px;
  height: 20px;
  transform: rotate(90deg);
  fill: var(--primary);
  stroke: var(--primary);
  stroke-width: 2px;
}

.create-post-flow-button-container {
  width: 100%;
  margin-top: 0px !important;
}

.create-post-flow-button-container > svg {
  stroke: var(--text-light);
  fill: var(--text-dark); 
}

.create-post-flow-button-container-active > svg {
  stroke: var(--primary);
}

.checkbox-outer-wrapper-CreateListing {
  width: 100%;
  max-width: 500px;
  margin-left: 5%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.checkbox-inner-wrapper-CreateListing {
  min-width: 140px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.checkbox-days-inner-wrapper-CreateListing {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.time-selector-wrapper-CreateListing {
  width: 100%;
  max-width: 500px;
  margin: 5px 0px 0px 5% !important;
  display: flex;
  padding-bottom: 10px;
  justify-content: space-around;
  align-items: center;
}

.submit-outer-wrapper-CreateListing {
  width: calc(100% - 20px);
  max-width: 500px;
  margin-left: 5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.card-SignUp-description {
  width: 90%;
  margin: 10px 0px 0px 5% !important;
  text-align: center;
  font-size: 1.15rem !important;
  font-weight: 500;
  color: var(--text-dark);
}

.submit-text-CreateListing {
  width: calc(100% - 10%);
}

.submit-inner-wrapper-CreateListing {
  width: 100%;
  margin: 10px 0 30px -5%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.consent-wrapper-CreateListing {
  display: flex;
  justify-content: center;
  align-items: center;
}

.consent-text-CreateListing {
  margin-left: 5px;
  font-size: 0.9rem;
}

.consent-terms-container {
  display: flex;
}

.submit-button-container {
  width: 90%;
  padding-bottom: 20px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.consent-terms-link {
  color: var(--text-dark);
  text-decoration: underline;
  cursor: pointer;
}

.consent-terms-arrow {
  width: 15px;
  margin-left: 5px;
  fill: var(--text-medium);
}

.image-preview-create-listing {
  width: 100px;
  min-height: 75px;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  image-orientation: 0deg;
}

.checkbox-label {
  margin-bottom: 4px;
}

.guildelines-wrapper {
  max-height: 80vh;
  padding-bottom: 10px;
  overflow-y: scroll;
}

.guildelines-title {
  width: 100%;
  text-align: center;
  color: var(--text-dark);
}

.guildelines-sub-title {
  width: 100%;
  text-align: left;
  font-weight: 700;
  color: var(--text-dark);
}

.new-post-social-share-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}

.new-post-social-share-container {
  display: flex;
  margin: 0px 5px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.new-post-split-container {
  width: 90%;
  max-width: 500px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
}

.new-post-legend {
  width: 90%;
  max-width: 500px;
  margin: 5px 0px 0px 5% !important;
  font-size: 0.9rem;
}

.social-media-facebook-icon {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

@media only screen and (max-width: 600px) {
  .checkbox-outer-wrapper-CreateListing {
    max-width: calc(100vw - 10%);
  }

  .time-selector-wrapper-CreateListing {
    max-width: calc(100vw - 10%);
    justify-content: space-around;
  }
}
