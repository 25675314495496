.cant-find-container {
  max-width: 600px;
  height: 260px;
  max-height: 260px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background: linear-gradient(var(--primary), var(--primary-dark), var(--primary));
  box-shadow: 0 0 6px rgba(10, 10, 10, 1);
  cursor: pointer;
}

.cant-find-title {
  font-size: 1.35rem;
  font-weight: 600;
  text-align: center;
  color: var(--stay-white);
}

.cant-find-description {
  margin-top: 5px;
  font-size: 1.1rem;
  text-align: center;
  color: var(--stay-white);
}