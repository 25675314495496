.tickets-sales-title-container {
  width: 100%;
  height: 30px;
  padding: 0px 10px 8px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-end;
  border-bottom: 1px solid var(--text-medium);
}

.tickets-sales-title {
  font-size: 1.2rem;
  font-weight: 500;
}

.tickets-sales-text {
  white-space: nowrap;
}

.tickets-sales-multiple-dates-container {
  width: 100%;
  padding: 3px 0px;
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  overflow-x: scroll;
}

.tickets-sales-multiple-dates-text {
  padding: 6px 12px;
  margin-right: 10px;
  font-weight: 500;
  white-space: nowrap;
  color: var(--stay-white);
  background-image: linear-gradient( var(--primary), var(--primary-dark), var(--primary));
  border-radius: 4px;
  box-shadow: 1px 1px 3px rgba(0,0,0,0.2);
}

.tickets-sales-multiple-dates-text:hover {
  box-shadow: 1px 1px 3px rgba(0,0,0,0.4);
}

.tickets-sales-multiple-dates-text-active {
  padding: 6px 12px;
  font-weight: 600;
  color: var(--text-dark);
  background-image: none;
  background-color: var(--backgroundColor);
  box-shadow: inset 0px 0px 6px rgba(0, 0, 0, 0.8);
}

.tickets-sales-multiple-dates-text-active:hover {
  box-shadow: inset 0px 0px 6px rgba(0, 0, 0, 0.8);
}

.tickets-sales-multiple-dates-load-more {
  color: var(--stay-white);
  background-image: linear-gradient( var(--primary), var(--primary-dark), var(--primary));
  border: none;
}

.ticket-sale-wrapper {
  padding-bottom: 5px;
  margin-top: 10px;
  max-height: 350px;
  overflow: scroll;
}

.ticket-sale-wrapper > *:not(:first-child) {
  margin-top: 10px;
}

.ticket-sale-container {
  width: 100%;
  padding: 5px 10px;
  min-height: 70px;
  position: relative;
  border-radius: 4px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border: 1px solid var(--text-medium);
  box-shadow: 1px 1px 3px rgba(0,0,0,0.4);
  overflow: hidden;
}

.ticket-sale-container:hover {
  box-shadow: 1px 1px 5px rgba(0,0,0,0.7);
}

.ticket-sale-overlay {
  width: 100%;
  height: 100%;
  background-image: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.7));
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.ticket-content-container {
  position: relative;
  color: var(--stay-white);
  z-index: 1;
}

.ticket-sale-title-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-start;
  z-index: 1;
}

.ticket-sale-title {
  font-size: 1.2rem;
  font-weight: 700;
  text-shadow: 0px 0px 2px rgba(0,0,0,0.8);
}

.ticket-sale-price-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: flex-end;
}

.ticket-sale-originalPrice {
  margin: 0px 5px 1px 0px;
  position: relative;
  font-size: 1rem;
  font-weight: 700;
}

.ticket-sale-originalPrice:before {
  position: absolute;
  content: "";
  left: 0;
  top: 50%;
  right: 0;
  color: var(--secondary);
  border-top: 2px solid var(--secondary);
  border-color: inherit;
  -webkit-transform:rotate(-10deg);
  -moz-transform:rotate(-10deg);
  -ms-transform:rotate(-10deg);
  -o-transform:rotate(-10deg);
  transform:rotate(-10deg);
}

.ticket-sale-price {
  font-size: 1.2rem;
  font-weight: 700;
}

.ticket-sale-times {
  margin-top: 2px;
  font-size: 0.9rem;
}

.ticket-redemption-text {
  text-align: right;
}