.created-ticket-card {
  width: calc(100% - 40px);
  max-width: 600px;
  flex: 350px;
  margin: 5px;
  position: relative;
  border-radius: 5px;
  background-color: rgb(247, 246, 246);
  background-color: var(--backgroundColor);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: 1px 1px 5px rgba(10, 10, 10, 0.9);
  overflow: hidden;
}

.created-ticket-card-hover:hover {
  box-shadow: 2px 2px 8px rgba(10, 10, 10, 0.6);
}

.created-ticket-title-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
}

.created-ticket-title {
  font-size: 1.1rem;
  font-weight: 800;
  text-decoration: underline;
}

.created-ticket-title-right-container {
  width: 50%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: flex-end;
}

.created-ticket-date-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: center;
}

.created-ticket-time {
  font-weight: 600;
  white-space: nowrap;
}

.ticket-form-split-container {
  width: calc(100% - 20px);
  padding-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
}

.created-ticket-no-ticket {
  text-align: center;
  color: var(--secondary);
}

.tickets-title-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.tickets-title-text {
  width: 100%;
  text-align: center;
}

.tickets-subtitle-text {
  width: 100%;
  text-align: center;
  font-size: 1.3rem;
  font-weight: 700;
}

.tickets-description-text {
  width: 100%;
  text-align: center;
}

.tickets-ad-container {
  width: calc(100% - 40px);
  max-width: 600px;
  height: 100px;
  flex: 350px;
  margin: 5px;
  position: relative;
  border-radius: 5px;
  background-color: rgb(247, 246, 246);
  background-color: var(--backgroundColor);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: 1px 1px 5px rgba(10, 10, 10, 0.9);
  overflow: hidden;
  cursor: pointer;
}

.tickets-ad-container > div {
  margin: 0px !important;
}

.tickets-ad-container:hover {
  box-shadow: 2px 2px 8px rgba(10, 10, 10, 0.9);
}

.ticket-adjusted-price-text {
  width: 90%;
  text-align: right;
  font-weight: 600;
  color: var(--primary);
}

.strip-onboarding-card {
  width: calc(100% - 20px);
  max-width: 650px;
  min-height: 220px;
  padding: 0px 10px;
  margin-top: 40px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
  margin-top: 12px;
  background-color: rgb(247, 246, 246);
  background-color: var(--backgroundColor);
  box-shadow: 0 0 2px rgba(10, 10, 10, 0.8);
  border-radius: 4px;
}

.strip-onboarding-logo-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.strip-onboarding-eventhopund-name {
  width: 260px;
  height: 26px;
  fill: var(--primary);
}

.strip-onboarding-logo-text {
  margin: 0px 10px;
  font-size: 1.2rem;
}

.strip-onboarding-logo {
  height: 40px;
}

.strip-onboarding-tagline-wrapper {
  width: 100%;
  margin-top: 2px !important;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.strip-onboarding-tagline {
  padding-top: 3px;
  font-size: 1.4rem;
  font-weight: 600;
  text-align: center;
  color: var(--text-dark);
  border-top: 1px solid var(--text-dark);
}

.strip-onboarding-description-text {
  width: 100%;
  font-size: 1.1rem;
  text-align: center;
}

.strip-onboarding-must-use {
  width: 100%;
  text-align: center;
  font-size: 1.1rem;
  font-weight: 600;
  color: var(--secondary);
}

.strip-onboarding-button-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.sales-type-text {
  width: 100%;
  padding: 0px 10px;
  margin-top: 0px !important;
  margin-bottom: 10px;
  text-align: center;
}

.created-ticket-not-available-container {
  margin-top: 0px !important;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: rgba(10, 10, 10, 0.8);
}

.created-ticket-not-available-text {
  font-size: 1.2rem;
  text-align: center;
  color: var(--stay-white);
}

.tickets-presale-container {
  width: calc(100% - 20px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.1rem;
}