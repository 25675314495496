.skeleton-card-container {
  flex: 350px;
  height: 260px;
  position: relative;
  flex: 350px;
  border-radius: 10px;
  box-shadow: 0 0 4px rgba(10, 10, 10, 0.8);
  background-color: rgb(236, 236, 236);
  background-color: var(--backgroundColor);
  background-image: linear-gradient(
    90deg,
    rgb(247, 247, 247) 22%,
    rgba(210, 210, 210, 0.6) 30%,
    rgb(247, 247, 247) 38%
  );
  background-image: linear-gradient(
    90deg,
    var(--backgroundColor) 22%,
    var(--skeleton-opacity) 30%,
    var(--backgroundColor) 38%
  );
  background-size: 200%;
  animation: skeletonAnimate 1.4s infinite linear;
  -webkit-animation-duration: 1.4s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-name: skeletonAnimate;
  -webkit-animation-timing-function: linear;
}

@-webkit-keyframes skeletonAnimate {
  from {
    background-position: 100%;
  }
  to {
    background-position: -100%;
  }
}

@keyframes skeletonAnimate {
  from {
    background-position: 100%;
  }
  to {
    background-position: -100%;
  }
}

.skeleton-card-data-container {
  width: 95%;
  height: 70px;
  margin-left: 2.5%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  bottom: 0;
  left: 0;
}

.skeleton-card-title-container {
  width: 200px;
  height: 20px;
  margin-bottom: 8px;
  border-radius: 5px;
  background-color: rgb(210, 210, 210);
  background-color: var(--skeleton);
}

.skeleton-card-name-container {
  width: 100px;
  height: 15px;
  border-radius: 5px;
  background-color: rgb(210, 210, 210);
  background-color: var(--skeleton);
}

.skeleton-card-time-title-container {
  width: 60px;
  height: 13px;
  margin-top: 5px;
  border-radius: 5px;
  background-color: rgb(210, 210, 210);
  background-color: var(--skeleton);
}

.skeleton-card-time {
  width: 60px;
  height: 18px;
  margin-top: 5px;
  border-radius: 5px;
  background-color: rgb(210, 210, 210);
  background-color: var(--skeleton);
}
