.sign-in-modal-wrapper {
  width: 400px;
  max-width: 90%;
  min-height: 250px;
  max-height: 90vh;
  padding: 15px 15px 20px 15px;
  position: fixed;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 5px;
  color: #323435;
  color: var(--text-dark);
  background-color: rgb(247, 246, 246);
  background-color: var(--backgroundColor);
  box-shadow: 2px 2px 10px #333;
  overflow: scroll;
  visibility: hidden;
}

.sliderValueCloseIcon {
    width: 22px;
    height: 22px;
    position: absolute;
    top: 20px;
    right: 20px;
    fill:  var(--text-medium);
    cursor: pointer;
  }

  .sliderTitleImage {
    width: 15%;
    max-height: 75px;
  }
  
  .sliderLogoName {
    width: 70%;
    max-width: 300px;
    height: 25px;
    margin-top: 10px;
    fill: var(--text-dark)
  }

  .sliderDescription {
    margin-top: 15px;
    font-size: 1.2rem;
    font-weight: 600;
    text-align: center;
    color: var(--text-dark)
  }
  
  .loginButtonsWrapper {
    width: 100%;
    padding-bottom: 20px;
    margin-top: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .sliderValueContainer {
    width: 95%;
    margin-top: 15px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  .sliderValueIconContainer {
    min-width: 24px;
    width: 24px;
    height: 24px;
    margin-right: 10px;
    display: flex;
    justify-content: center;
    text-align: center;
    fill: var(--primary);
  }

  .sliderValueText {
    font-size: 16;
    color: rgb(80, 80, 80);
  }