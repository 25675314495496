.social-media-login-conatiner {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.social-media-buttons-conatiner {
  width: 100%;
  margin-bottom: 15px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}

.social-login-select-button {
  width: 150px;
  min-width: 150px;
  height: 48px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  box-shadow: 2px 2px 4px rgb(40, 40, 40, 40%);
  cursor: pointer;
}

.social-media-login-conatiner > span {
  margin-top: 20px;
}

.social-login-title-container {
  padding: 8px 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  border-radius: 22px;
  color: var(--text-dark);
}

.bgc-medium {
  background-color: var(--text-medium);
}

.social-title-icon-container {
  width: 24px;
  height: 24px;
  margin-right: 8px;
  fill: var(--text-dark);
}

.social-login-icon-container {
  width: 24px;
  height: 24px;
  margin-right: 8px;
  fill: var(--stay-white);
}

.social-login-title-text {
  font-size: 1.15rem;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  color: var(--text-dark);
}

@media only screen and (min-width: 750px) {
  .social-login-title-container {
    padding: 8px 25px;
  }

  .social-login-title-text {
    font-size: 1.3rem;
  }
}

.social-media-content-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
}

.social-media-details-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.social-media-facebook-remove-container {
  min-width: 225px;
  width: 40%;
  margin: 10px 0px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;
}

.social-media-facebook-remove-left-container {
  height: 40px;
  padding: 0px 25px 0px 25px;
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  border: 1px solid #394499;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.social-media-facebook-remove-icon-container {
  position: relative;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 25px;
}

.social-media-facebook-check-container {
  width: 20px;
  height: 20px;
  position: absolute;
  top: -2px;
  right: -7px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: 2px solid var(--backgroundColor);
  background-color: green;
}

.social-media-facebook-check {
  width: 10px;
  height: 10px;
  margin-top: -6px;
  fill: var(--stay-white);
}

.social-media-facebook-remove-icon {
  width: 30px;
  height: 28px;
  fill: var(--stay-white);
}

.social-media-facebook-remove-text {
  font-size: 1.1rem;
  font-weight: 600;
  color: #394499;
}

.social-media-list-title {
  margin-top: 10px;
  font-weight: 600;
  color: var(--text-dark);
}

.social-media-list-item {
  margin-left: 40px;
  color: var(--text-dark);
  text-decoration: dotted;
}

.social-media-list-item > li {
  margin-top: 5px;
}

.social-media-list-item > li > a {
  color: var(--primary);
}

.social-media-list-item > li > a:hover {
  text-decoration: underline;
}

.social-media-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.social-media-wrapper > img {
  margin-right: 10px;
}

.social-media-icons {
  width: 50px;
  height: 50px;
}

.social-media-button {
  min-width: 180px;
  height: 48px;
  padding: 0px 15px;
  margin-left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  text-transform: uppercase;
  border-radius: 2px;
  box-shadow: 2px 2px 4px rgb(40, 40, 40, 40%);
  background-image: linear-gradient(
    var(--primary),
    var(--primary-dark),
    var(--primary)
  );
  color: white;
  cursor: pointer;
}

.social-media-button:hover {
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.7);
}

.social-login-button-icon-container {
  width: 24px;
  height: 24px;
  fill: var(--stay-white);
}

.facebook_forground {
  box-shadow: none;
}

.facebook_forground > div > svg {
  fill: #337eb9 !important;
}

.facebook_forground > p {
  color: #337eb9 !important;
}

.instagram_forground {
  box-shadow: none;
}

.instagram_forground > div > svg {
  fill: #ee582c !important;
}

.instagram_forground > p {
  color: #ee582c !important;
}

.both_social_background {
  background-image: linear-gradient(115deg, #394499, #d1175e);
}
