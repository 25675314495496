.weekly-giveaway-wrapper {
  width: 100vw;
  height: calc(100vh - 40px);
  padding: 20px 15px;
  position: fixed;
  top: 40px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  color: white;
  background-image: linear-gradient(120deg, var(--primary), var(--primary-dark), var(--secondary-light));
  overflow: hidden;
  overflow-y: scroll;
}

.weekly-giveaway-container {
  max-width: 600px;
  padding: 10px 10px 25px 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: rgba(0,0,0,0.6);
  border-radius: 5px;
}

.weekly-giveaway-eventhound-icon {
  width: 15%;
  max-width: 60px;
  max-height: 75px;
}

.weekly-giveaway-LogoName {
  width: 70%;
  max-width: 300px;
  height: 25px;
  margin-top: 5px;
  fill: var(--stay-white);
}

.weekly-giveaway-tagline {
  font-size: 1.1rem;
}

.weekly-giveaway-title {
  margin-top: 20px;
  border-bottom: 1px solid white;
}

.weekly-giveaway-text {
  text-align: center;
  margin-top: 10px;
}

.weekly-giveaway-items {
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 5px;
  row-gap: 5px;
  justify-items: center;
}

.weekly-giveaway-item {
  width: 100%;
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  color: var(--stay-white);
}

.weekly-giveaway-item-date {
  font-size: 0.9rem;
  text-align: center;
}

.weekly-giveaway-image {
  width: 100%;
  height: 100px;
  margin-top: 4px;
  object-fit: cover;
  border-radius: 4px;
  box-shadow: 1px 1px 3px rgba(0,0,0,0.8);
}

.weekly-giveaway-item-title {
  margin-top: 4px;
  font-size: 0.9rem;
  text-align: center;
}

.weekly-giveaway-buttons-container {
  width: 100%;
  max-width: 400px;
  margin-top: 25px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.weekly-giveaway-button-container {
  width: 160px;
  height: 48px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: rgb(80,80,80);
  background-color: #f1f1f1;
  border-radius: 4px;
  box-shadow: 1px 1px 3px rgba(0,0,0,0.8);
}

.weekly-giveaway-icon {
  width: 15%;
  max-height: 75px;
  margin-right: 10px;
  fill: rgb(80,80,80);
}

.weekly-giveaway-button-text-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.weekly-giveaway-button-text-title {
  font-size: 0.9rem;
  white-space: nowrap;
}

.weekly-giveaway-button-text {
  font-size: 1.2rem;
  font-weight: 600;
}
