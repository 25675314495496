.result-card-container {
  max-width: 600px;
  height: 260px;
  padding: 10px 5px 5px 5px;
  position: relative;
  flex: 350px;
  border-radius: 10px;
  box-shadow: 0 0 6px rgba(10, 10, 10, 1);
  opacity: 1;
  overflow: hidden !important;
  z-index: 1;
}

.result-card-mobile {
  margin: 10px 4px 0 4px;
}

.result-card-web {
  margin: 5px;
  transition: all 0.5s ease-out;
}

.result-card-web:hover {
  transform: scale(1.05);
  z-index: 2;
}

.result-card-web:hover .result-card-image {
  transform: scale(1.1) translateY(-10px);
}

.result-card-starting {
  border: none;
}

.result-card-active {
  border: 1px solid var(--primary);
}

.result-card-ending {
  border: 1px solid var(--secondary);
}

.result-card-ended {
  border: 1px solid black;
}

.result-card-image {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  object-position: 50%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  transition: all 0.5s ease-out;
}

.result-skyline-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  fill: var(--backgroundColor);
  z-index: 0;
}

.result-card-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
}

.result-card-status-overlay {
  height: 10%;
  min-height: 12px;
}

.result-card-overlay-gradient {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.7) 20%, transparent 70%);
}

.result-card-overlay-day {
  background: linear-gradient(
    0deg,
    var(--opacity-light),
    var(--opacity-light),
    transparent 70%
  );
}

.result-card-overlay-starting {
  /* background: linear-gradient(0deg, var(--opacity-white-medium), transparent); */
}

.result-card-overlay-active {
  background: linear-gradient(0deg, var(--primary-opacity-dark), transparent);
}

.result-card-overlay-ending {
  background: linear-gradient(0deg, var(--secondary-opacity-dark), transparent);
}

.result-card-overlay-ended {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), transparent);
}

.result-features-container {
  width: 100%;
  padding: 0 1px 10px 0;
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: nowrap;
  background: linear-gradient(rgba(0, 0, 0, 0.6) 20%, transparent);
}

.result-feature-container {
  min-width: 28px;
  width: 28px;
  height: 28px;
  padding: 5px;
  margin: 3px;
  background-color: rgba(0,0,0,0.5);
  border-radius: 4px;
}

.result-feature-container > svg {
  fill: var(--stay-white);
}

.result-info-bottom {
  position: absolute;
  bottom: 14px;
  left: 8px;
}

.result-info-container {
  width: calc(100% - 100px);
  color: var(--stay-white);
  z-index: 2;
}

.result-title-container {
  display: flex;
  justify-content: flex-start;
}

.result-title {
  font-size: 1.2rem;
  font-weight: 600;
  letter-spacing: 0.1rem;
  line-height: 1.7rem;
  text-shadow: 1px 1px 4px rgba(0, 0, 0, 1);
  overflow: hidden;
}

.result-name-container {
  display: flex;
  justify-content: flex-start;
}

.result-business-name {
  max-width: 100%;
  padding-top: 3px;
  margin-top: 2px;
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: 0.05rem;
  border-top: 1px  solid var(--stay-white);
  text-shadow: 1px 1px 4px rgba(0, 0, 0, 1);
  overflow: hidden;
}

.result-time-container {
  position: absolute;
  bottom: 14px;
  right: 8px;
  color: var(--stay-white);
  z-index: 2;
}

.result-time-title {
  margin-bottom: 2px;
  font-size: 0.8rem;
  font-weight: 700;
  text-align: center;
  text-shadow: 1px 1px 4px rgba(0, 0, 0, 1);
}

.result-time {
  margin-top: 1px;
  text-align: center;
  font-size: 1.2rem;
  font-weight: 300;
  text-shadow: 1px 1px 4px rgba(0, 0, 0, 1);
}

.card-is-starting {
  color: var(--stay-white);
}

.card-is-active {
  color: var(--primary);
}

.card-is-ending {
  color: var(--secondary);
}

.card-is-ended {
  color: ver(--text-light)
}

.result-text-spacer {
  width: 6px;
  margin-right: 3px;
}

.result-card-covid {
  width: 25px;
  height: 25px;
  position: absolute;
  top: 10px;
  left: 10px;
  cursor: pointer;
  z-index: 2;
}

.business-card-covid {
  width: 35px;
  height: 35px;
  position: absolute;
  top: 55px;
  left: 15px;
  cursor: pointer;
  z-index: 2;
}


.zoomIn {
  animation-duration: 300ms;
  animation-fill-mode: both;
  animation-name: zoomIn;
}

@keyframes zoomIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
