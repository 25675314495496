.type-title-description {
  text-align: center;
  font-size: 1.15rem !important;
  font-weight: 500;
  color: var(--text-dark);
}

.legend-form-secondary {
  text-align: center;
  font-size: 1.2rem !important;
  font-weight: 600;
  color: var(--secondary) !important;
}
