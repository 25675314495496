.full-page-carousel-wrapper {
  width: 100vw;
  margin-top: 40px;
  position: relative;
}

.carousel-page-background-image {
  width: 100vw;
  min-width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  object-fit: cover;
  object-position: 50%;
}

.carousel-container {
  position: fixed;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: flex-start;
  list-style: none;
  transition: transform 0.3s cubic-bezier(0.694, 0.0482, 0.335, 1);
  background-color: rgba(0,0,0,0.25);
}

.carousel-item {
  width: 100vw;
  padding: 10px 0px 60px 0px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow-y: scroll;
}

.carousel-video-container {
  width: 100%;
  height: 180px;
}

.carousel-text {
  font-size: 1.1rem;
  font-weight: 500;
  color: var(--text-dark);
}

.carousel-text-highlight {
  text-align: center;
  font-size: 1.2rem;
  font-weight: 900;
  line-height: 22px;
  text-shadow: 1px 1px 3px rgba(0,0,0,0.7);
}

.carousel-item-image {
  width: 60%;
  max-width: 280px;
}

.carousel-logo {
  max-width: 50%;
  height: 38%;
  padding: 15px 0px;
}

.carousel-logo > svg {
  fill: var(--stay-white);
}

.carousel-item-content-container {
  width: calc(100vw - 30px);
  max-width: 600px;
  flex-shrink: 0;
  padding: 10px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  color: var(--stay-white);
  border-radius: 4px;
  background-color: var(--backgroundColor);
  box-shadow: 2px 2px 20px rgba(0,0,0,0.8);
}

.carousel-header-title {
  margin-bottom: 5px;
  font-size: 1.3rem;
  text-align: center;
  color: var(--stay-white);
  text-shadow: 1px 1px 3px rgba(0,0,0,0.7);
}

.carousel-title {
  margin-bottom: 15px;
  font-size: 1.7rem;
  text-align: center;
  color: var(--text-dark);
}

.carousel-sub-title {
  width: 100%;
  margin-bottom: 5px;
  font-size: 1.3rem;
  font-weight: 700;
  text-align: center;
  color: var(--text-dark);
}

.business-onboarding-arrow-outer {
  width: 100%;
  margin-top: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.business-onboarding-arrow-inner {
  width: 18px;
  height: 20px;
  transform: rotate(-90deg);
  fill: var(--primary);
  stroke: var(--primary);
  stroke-width: 2px;
}

.business-onboarding-sub-title {
  width: 100%;
  margin: 5px 0px;
  font-size: 1.2rem;
  font-weight: 600;
  text-align: center;
  color: var(--text-dark);
}

.carousel-item-title {
  margin-top: 10px;
  font-size: 1.5rem;
  text-align: center;
  text-shadow: 1px 1px 3px rgba(0,0,0,0.7);
}

.carousel-item-text {
  margin-top: 10px;
  font-size: 1.1rem;
  font-weight: 500; 
  line-height: 23px;
  text-shadow: 1px 1px 2px rgba(0,0,0,0.6);
}

.carousel-list-container {
  max-width: calc(100% - 30px);
  padding-left: 10px;
}

.carousel-list-item {
  margin-top: 10px;
  font-size: 1.1rem;
  font-weight: 500;
  color: var(--text-dark);
}

.carousel-button-container {
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.carousel-button {
  height: 30px;
  min-width: 150px;
  margin: 5px;
  font-size: 1.1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 2px;
  color: var(--stay-white);
  background-image: linear-gradient( var(--primary), var(--primary-dark), var(--primary));
  box-shadow: 2px 2px 4px rgba(40, 40, 40, 0.4);
  cursor: pointer;
}

.carousel-button-secondary {
  height: 30px;
  min-width: 150px;
  margin: 5px;
  font-size: 1.1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 2px;
  color: var(--stay-white);
  background-image: linear-gradient( var(--secondary), var(--secondary-dark), var(--secondary));
  box-shadow: 2px 2px 4px rgba(40, 40, 40, 0.4);
  cursor: pointer;
}

.carousel-prev,
.carousel-next {
  width: 25px;
  height: 35px;
  padding: 0px 3px;
  position: absolute;
  top: calc(50% - 30px);
  border-radius: 4px;
  fill: var(--stay-white);
  background-color: rgba(0,0,0,0.55);
  cursor: pointer;
  z-index: 10;
  transition: opacity 0.3s ease;
}

.carousel-prev {
  left: 10px;
  transform: rotate(180deg);
}

.carousel-next {
  right: 10px;
}

.carousel-prev:hover,
.carousel-next:hover {
  opacity: 1;
}

.carousel-pagination {
  list-style: none;
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
  width: 50%;
  padding: 0;
  margin: 0 auto;
  text-align: center;
  z-index: 10;
}

.carousel-bullet {
  display: inline-block;
  width: 12px;
  height: 12px;
  background-color: #000;
  cursor: pointer;
  margin: 0 7px;
  border-radius: 50%;
  opacity: 0.5;
  transition-property: transform, opacity, background-color;
  transition-duration: 0.3s;
}

.carousel-bullet-active {
  background-color: transparent;
  border: 2px solid rgba(0,0,0,0.5);
  cursor: none;
}

.carousel-bullet:hover {
  -webkit-transform: scale(1.3);
  transform: scale(1.3);
}

.carousel-bullet.active-bullet, 
.carousel-bullet.active-bullet:hover {
  opacity: 1;
  -webkit-transform: scale(1.3);
  transform: scale(1.3);
  background-color: #fff;
  cursor: default;
}

.listing-flow-wrapper {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.listing-flow-conatiner {
  width: 55%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.listing-flow-title {
  font-size: 1.4rem;
  font-weight: 700;
  color: var(--text-dark)
}

.listing-flow-fine-text {
  width: 100%;
  margin-top: 8px;
  flex-shrink: 0;
  font-size: 1.1rem;
  font-weight: 600;
  text-align: center;
  color: var(--primary)
}

.listing-flow-image-shadow {
  width: calc(100% - 20px);
  height: calc(100% - 15px);
  position: absolute;
  top: 10px;
  left: 8px;
  border-radius: 42px;
  transition: all 0.5s ease
}

.listing-flow-image {
  width: 100%;
  height: 100%;
  position: absolute;
  display: none;
  transition: all 0.5s ease;
  cursor: pointer;
  z-index: 2;
}

.listing-flow-image-placeholder {
  height: 100%;
  margin-top: 5px;
  position: relative;
  top: 0px;
  flex-shrink: 0;
  display: block;
  visibility: hidden;
}

.listing-flow-image-show {
  display: block;
}

.listing-flow-conatiner:hover .listing-flow-image-shadow {
  transform: scale(1.4);
  transform-origin: top center;
  background-color: rgba(0,0,0,0.8);
  box-shadow: 0px 10px 50px rgba(0,0,0,1);
}
.listing-flow-conatiner:hover .listing-flow-image-0 {
  transform: scale(1.4);
  transform-origin: top center;
}
.listing-flow-conatiner:hover .listing-flow-image-1 {
  transform: scale(1.4);
  transform-origin: top center;
}
.listing-flow-conatiner:hover .listing-flow-image-2 {
  transform: scale(1.4);
  transform-origin: top center;
}
.listing-flow-conatiner:hover .listing-flow-image-3 {
  transform: scale(1.4);
  transform-origin: top center;
}
.listing-flow-conatiner:hover .listing-flow-image-4 {
  transform: scale(1.4);
  transform-origin: top center;
}

@media only screen and (min-width: 750px) {
  .carousel-item-content-container {
    padding: 25px 50px;
  }
  .carousel-video-container {
    height: 300px;
  }
}

.welcome-parnter-image-wrapper {
  width: 100%;
  height: 100%;
  padding: 5px 0px 20px 0px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  background-color: white;
  border-radius: 4px;
}

.welcome-parnter-image-wrapper > * {
  margin-top: 20px;
}

.welcome-parnter-image {
  width: 40%;
  object-fit: contain;
  object-position: 50% 50%;
}