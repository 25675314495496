.image-modal-background-wrapper {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  visibility: hidden;
  z-index: 150;
}

.image-modal-background {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.image-modal-background-image {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  -webkit-filter: blur(7px);
  -o-filter: blur(7px);
  -ms-filter: blur(7px);
  filter: blur(7px);
}

.image-modal-wrapper {
  display: flex;
  position: fixed;
  justify-content: center;
  align-items: center;
}

.image-modal-close-container {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 15px;
  right: 15px;
}

.image-modal-close-container > svg {
  fill: var(--stay-white);
}

.image-modal-image {
  width: auto;
  max-width: 90vw;
  height: auto;
  max-height: 90vh;
  border-radius: 5px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.7);
  overflow: hidden;
}
